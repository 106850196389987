<template>
  <div v-if="userModel">
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h1 class="text-md-h1 primary--text mb-5">User Details</h1>
            <v-simple-table v-if="$route.params.id != 0">
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>
                    {{ user.firstName ? userModel.firstName : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    {{ userModel.lastName }}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    {{ userModel.email ? userModel.email : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Telephone Number</td>
                  <td>
                    {{ userModel.telNumber ? userModel.telNumber : "" }}
                  </td>
                </tr>
                <tr></tr>

                <tr>
                  <td>FullAddress</td>
                  <td>
                    {{ userModel.fullAddress ? userModel.fullAddress : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Consultant ID</td>
                  <td>
                    {{ userModel.consultant ? userModel.consultant : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Title</td>
                  <td>
                    {{ userModel.title ? userModel.title : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Bio</td>
                  <td>
                    {{ userModel.bio ? userModel.bio : "" }}
                  </td>
                </tr>

                <!-- <tr>
                  <td>DOB</td>
                  <td>
                    {{ userModel.dob ? userModel.dob : "" }}
                  </td>
                </tr> -->
                <tr>
                  <td>Unique User ID</td>
                  <td>
                    {{ userModel.id ? userModel.id : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Region</td>
                  <td>
                    {{ userModel.region ? userModel.region : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Race</td>
                  <td>
                    {{ userModel.race ? userModel.race : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>
                    {{ userModel.gender ? userModel.gender : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Postal Code</td>
                  <td>
                    {{ userModel.postalCode ? userModel.postalCode : "" }}
                  </td>
                </tr>

                <tr>
                  <td>Relationship Status</td>
                  <td>
                    {{ userModel.relationship ? userModel.relationship : "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <v-btn
                v-if="$route.params.id == 0"
                color="primary"
                :to="`${$route.path}/edit`"
                class="mr-2"
                >Create a New User</v-btn
              >

              <v-btn
                v-else
                color="primary"
                :to="`${$route.path}/edit`"
                class="text-align-right"
                ><h1>Edit</h1>
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { User } from "@/models/User";
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,
      userModel: new User(),
      me: "",
      search: null,
      crumbs: [
        {
          text: "Employees",
          disabled: false,
          href: "/admin/users",
        },
        {
          text: "Employee Details",
          disabled: true,
          href: `/admin/user/${this.$route.params.id}`,
        },
      ],
    };
  },
  created() {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            email
            telNumber

            consultant
            race
            # dob
            title
            bio
            fullAddress
            gender
            title
            region
            postalCode
            relationship
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.isLoading = false;
        this.userModel.id = this.user.id;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.email = this.user.email;
        this.userModel.telNumber = this.user.telNumber;

        this.userModel.fullAddress = this.user.fullAddress;
        this.userModel.consultant = this.user.consultant;
        this.userModel.title = this.user.title;
        this.userModel.bio = this.user.bio;
        // this.userModel.dob = this.user.dob;
        this.userModel.region = this.user.region;
        this.userModel.race = this.user.race;
        this.userModel.gender = this.user.gender;
        this.userModel.postalCode = this.user.postalCode;

        this.userModel.relationship = this.user.relationship;
        // this.userModel.dob = this.user.dob;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
